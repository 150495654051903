<template>
  <Calendar />
</template>

<script>
import Calendar from "./components/LunarCalendar.vue";

export default {
  name: "App",
  components: {
    Calendar,
  },
};
</script>
